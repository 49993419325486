'use strict';
const adaptive = () => {

    /**
     * Корпоративным клиентам в слайдер
     **/
    if (document.querySelector('.corp__slider')) {
        if (document.documentElement.clientWidth < 640) {
            UIkit.slider('.corp__slider', {});
            document.querySelector('.corp__slider ul').classList.add('uk-slider-items');
        } else {
            UIkit.slider('.corp__slider', {}).$destroy();
            document.querySelector('.corp__slider ul').classList.remove('uk-slider-items');
        }
    }

    /**
     * Footer складывается в accordion на мобиле
     **/
    if (document.querySelector('.footer-accordion')) {
        if (document.documentElement.clientWidth < 640) {
            UIkit.accordion('.footer-accordion', {
                content: '.accordion-content',
                toggle: 'h3',
            });
        } else {
            UIkit.accordion('.footer-accordion', {}).$destroy();
            document.querySelectorAll('.footer-accordion .accordion-content').forEach(it => it.removeAttribute('hidden'));
            document.querySelector('.footer-accordion').classList.remove('uk-accordion');
        }
    }

    /**
     * About-list складывается в accordion на мобиле
     **/
    if (document.querySelector('.about-accordion')) {
        if (document.documentElement.clientWidth < 960) {
            UIkit.accordion('.about-accordion', {
                content: '.about-accordion__content',
                toggle: '.about-accordion__title',
            });
        } else {
            UIkit.accordion('.about-accordion', {}).$destroy();
            UIkit.heightMatch('.about-accordion', {});
            document.querySelectorAll('.about-accordion .about-accordion__content').forEach(it => it.removeAttribute('hidden'));
            document.querySelector('.about-accordion').classList.remove('uk-accordion');
        }
    }

    /**
     * Filter в каталоге, складываем в аккордион на мобиле
     **/
    if (document.querySelector('.catalog-filter__accordion')) {
        if (document.documentElement.clientWidth < 640) {
            UIkit.accordion('.catalog-filter__accordion', {
                targets: '> * > *',
                content: '.filter-item__content',
                toggle: '.uk-button-default',
            });
            document.querySelector('.catalog__offcanvas-main').append(document.querySelector('.catalog-filter__accordion'));
            document.querySelectorAll('.filter-item__content').forEach((it) => {
                UIkit.dropdown(it).$destroy();
                it.style = '';
                it.className = 'filter-item__content';
            });
        } else {
            UIkit.accordion('.catalog-filter__accordion').$destroy();
            document.querySelectorAll('.filter-item__content').forEach(it => it.removeAttribute('hidden'));
            UIkit.dropdown('.filter-item__content', {
                mode: 'click',
            });
            document.querySelector('.catalog-filter').append(document.querySelector('.catalog-filter__accordion'));
        }
    }
}

document.addEventListener('DOMContentLoaded', adaptive);
window.addEventListener('resize', adaptive);

/**
 * Menu
 **/

$('.main-nav li > .subnav').parent().addClass('has-subnav');

$('.main-nav li.has-subnav > a').each(function() {
    var $this = $(this);
    var myClone = $this.clone();
    var myCloneParent = $this.next('.subnav');
    myClone.prependTo(myCloneParent).wrap('<li class="menu-title"></li>');
    myCloneParent.prepend("<li><a class='back-track'>Назад</a></li>");

});

$('.main-nav li.has-subnav > a').click(function(e) {
    e.preventDefault();
    var $this = $(this);
    $this.parent().parent().addClass('uk-overflow-hidden').scrollTop(0);
    $this.next('.subnav').addClass('active');
    $('.main-nav').scrollTop(0);
});

$('.main-nav a.back-track').click(function() {
    var $this = $(this);
    $this.parent().parent().removeClass('active');
    $this.parent().parent().parent().parent().removeClass('uk-overflow-hidden');
});

//гамбургер
$('.hamburger').on('click', function () {
    $('.menuMenu').addClass('menuMenu-active');
    $('body').addClass('menuMenu-body');
    $('.menuMenu-overlay').addClass('menuMenu-overlay_act');
});
$('.menuMenu-overlay').on('click', function () {
    $('.menuMenu').removeClass('menuMenu-active');
    $('body').removeClass('menuMenu-body');
    $('.menuMenu-overlay').removeClass('menuMenu-overlay_act');
});
$('.menuMenu-close').on('click', function () {
    $('.menuMenu').removeClass('menuMenu-active');
    $('body').removeClass('menuMenu-body');
    $('.menuMenu-overlay').removeClass('menuMenu-overlay_act');
});

if ($(".product__view-desc").innerHeight() > 400) {
    $(".show-desc").show();
    $(".product__view-desc").addClass("h300");
    $(".show-desc").click(function (e) {
        $(".product__view-desc").addClass("full");
        $(".show-desc").hide();
        e.preventDefault();
    });
}

$(document).ready(function() {
    $('.prev').click(function () {
        var $input = $(this).parent().find('input');
        var count = parseInt($input.val()) - 1;
        count = count < 1 ? 1 : count;
        $input.val(count);
        $input.change();
        return false;
    });
    $('.next').click(function () {
        var $input = $(this).parent().find('input');
        $input.val(parseInt($input.val()) + 1);
        $input.change();
        return false;
    });
});